import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
export default {
  zh: {
    status: {
      success: "成功",
      all: "所有文档",
      pending: "待定",
      signed: "已签署",
      rejected: "已拒绝",
      pv: "等待确认",
      act: "已激活",
      ina: "已停用",
      warning: "警告",
      created: "已创建",
    },
    menu: {
      sysAdmin: "系统管理",
      us: "不合格的船舶",
      jm: "码头管理",
      tm: "储罐管理",
      cm: "公司管理",
      pm: "船员管理",
      bn: "驳回提名",
      quantity: "数量证明书",
      quality: "质量证书",
      billOfLading: "提货单",
      requests: "要求",
      dashboard: "仪表盘",
      auditlog: "审计日志",
      excel: "Excel",
      equipmentReport: "设备报告",
    },
    us: {
      addShip: "添加船舶",
      addUser: "添加用户",
    },
    jm: {
      modifyBerth: "修改泊位",
      createBerth: "创建泊位",
      berthName: "泊位名称",
      minimumLOA: "最低 LOA",
      maximumLOA: "最高 LOA",
      Name: "名称",
      AddUser: "新增用户",
      editUser: "编辑用户",
      EmailAddress: "电子邮箱",
      Role: "角色",
      CreateCompany: "创建公司",
      CompanyName: "公司名称",
      UENNumber: "机构识别号码",
      Assigned: "指定分配",
      AllSurveyorCompanies: "所有测量公司",
    },
    table: {
      no: "编号",
      shipName: "船名",
      Name: "名称",
      iMONumber: "国际海事组织编号",
      LOA: "授权书",
      portOfRegistry: "登记港",
      EmailAddress: "电子邮件地址",
      Role: "角色",
      Status: "身份",
      customer: "顾客",
      barge: "驳船",
      product: "产品",
      quantity: "数量",
      brt: "驳船准备时间",
      pilot: "飞行员",
      bat: "泊位分配时间",
      jetty: "码头",
      ETA: "预计到达时间",
      bargeName: "驳船名称",
      contact: "PSA联系电话",
      bargeReadiness: "驳船准备",
      pilotExemption: "试点豁免",
      jettyNo: "码头号",
      berthAssignTiming: "泊位分配时间",
      estimatedBerthTiming: "预计靠泊时间",
      nomQtyMax: "Nom. Qty Max",
      qty: "数量",
      BAL: "BAL",
      tank: "罐",
      tanks: "罐",
      SUR: "SUR",
      block: "拒绝",
      doc: "文档",
      remarks: "备注",
      surveyor: "验船师",
      surveyors: "验船师",
      typeofPilot: "飞行员类型",
      jettyInformation: "码头资讯",
      nextETA: "下一步预计到达时间",
      Lcq: "链接数量证书",
      Lcoq: "链接质量证书",
      Lbl: "链接提货单",
      Product: "产品",
      BalanceTank: "罐内平衡",
      BargingInformation: "驳船提名资料",
      contactnumber: "Barge联系电话",
      psacontactnumber: "PSA联系电话",
      Opeinfo: "船舶信息",
      equipment: "设备",
      reportIssue: "报告问题",
      reportedOn: "报告时间",
      reportedBy: "报告者",
      resolvedOn: "解决于",
      addPilot: "添加飞行员",
      addTugBoat: "添加拖船",
      name: "名称",
      createdBy: "创建者",
      createdOn: "创建日期",
    },
    common: {
      allTanks: "所有码头",
      jettySummary: "码头总结",
      mapView: "地图视图",
      cardView: "卡片视图",
      ompt: "石油海洋私人有限公司",
      ppl: "石油私人有限公司",
      cpl: "冠军私人有限公司.",
      globalOil: "全球石油",
      genesisEnergy: "创世纪能源",
      oracleCompany: "甲骨文公司",
      ubn: "未分配的驳回提名",
      shipInformation: "船舶信息",
      ShipAISLocation: "船舶 AIS 位置",
      CHAMPION: "冠军",
      vesselIMONumber: "船舶 IMO 编号",
      iMOCompanyNumber: "IMO 公司编号",
      speed: "速度",
      course: "航向",
      draught: "吃水",
      timeline: "时间线",
      jettySchedule: "码头时间表",
      lastReport: "上次报告",
      status: "状态",
      Certificates: "证书",
      signDocument: "签署文件",
      title: "标题",
      eSignature: "E-Signature (Please draw in the box to sign)",
      Stamp: "邮票",
      AddImage: "Add Image",
      FileName: "文件名",
      DateCreated: "创建日期",
      link: "关联",
      SurveyorCompany: "测量公司",
      CustomerCompany: "客户公司",
    },
    tips: {
      xz: "请选择",
      sr: "请输入",
      sd: "开始日期",
      ed: "结束日期",
      du: `确定删除该{0}吗?`,
      sbo: `由{0}于{1}发送`,
      sto: `在{0}发送给{1}`,
      ro: `在{0}收到`,
      so: `在{0}发送`,
      noMsg: "暂无信息",
      st: "系统提示",
      rr: "您的登录状态已过期，您可以停留在此页面或再次登录",
      serverE: "服务器错误",
      onlyP: "只能上传.jpg、.png文件和pdf文件",
      must: "必填项必须填写",
      psd: "两次密码输入不一致！",
      url: "URL无效或已确认。",
      same: "不能添加相同的用户！",
      userO: "用户已过期",
      tips:
        "感谢您参与Marine Vessel Pass 数字身份系统测试。由于系统正处于试用期，在此期间生成的文件或签名将不被视为具有法律约束力。" +
        "\n" +
        "\n" +
        "如需更多信息，请发送电子邮件至support{'@'}bunkerchain.io或联系您的交易对手方以获取更多信息。",
    },

    btn: {
      activate: "激活",
      cdp: "更换显示图片",
      login: "登录",
      close: "关闭",
      edit: "编辑",
      save: "保存",
      add: "添加",
      delete: "删除",
      signed: "已签名",
      aa: "激活账号",
      create: "创建",
      search: "搜索",
      createDoc: "创建文档",
      searchDoc: "搜索文档",
      sign: "签名",
      reject: "拒绝",
      book: "预订",
      sta: "临时账户",
      au: "添加用户",
      eu: "编辑用户",
      du: "停用用户",
      eus: "激活用户",
      rr: "重发请求",
      dr: "删除请求",
      dus: "删除用户",
      cancel: "取消",
      send: "发送",
      comfirm: "确认",
      query: "查询",
      reset: "重置",
      createMsg: "创建信息",
      uf: "上传文件",
      next: "下一步",
      select: "选择",
      submit: "提交",
      re: "重新登录",
      download: "下载",
      share: "分享",
      sd: "分享文档",
      reply: "回复",
      lw: "使用IHS Markit登录",
      sa: "全选",
      modify: "调整",
      SearchTankNumber: "搜索油箱编号",
      CreateTank: "创建储罐",
      ModifyTank: "修改储罐",
      UploadCQ: "上传质量文件",
      UploadCOQ: "上传质量文件",
      AssignList: "分配列表",
      CreateCompany: "创建公司",
      SearchCompany: "搜索公司",
      cbn: "创建驳回提名",
      TankName: "油箱名称",
      TankNumber: "油箱编号",
      SelectAll: "所有",
      Today: "今天",
      ThisWeek: "本周",
      ThisMonth: "本月",
      ThisYear: "本年",
      Jput: "Jput",
      Customer: "客户",
      Surveyor: "测量师",
      Surveyors: "测量师",
      PendingApproval: "等待审批",
      Acknowledged: "承认",
      viewAll: "查看全部",
      rforModification: "修改请求",
      cancelBargingNomination: "取消驳回提名",
      remove: "刪除",
      Cbn: "创建驳船提名",
      created: "创建",
      BargeReady: "准备",
      JettyAssigned: "码头分配",
      Sip: "签署进度",
      Completed: "完成",
      Cancelled: "撤回",
      signIn: "登入",
      lastUpdate: "最后更新时间",
      CQUploaded: "CQ 已上传",
      Unlink: "取消链接",
      Linked: "链接",
      signasubmit: "签署并提交",
      signOfsubmit: "签署数量证明书",
      confirm: "确定",
      ReserSignature: "重置签名",
      SendDocument: "发送文档",
      JPUT: "JPUT",
    },
    ifm: {
      error: "验证码错误",
      pevc: "请输入验证码",
      ave: "验证电子邮件已发送到在公司IMO号/船舶IMO号下注册的电子邮件地址",
      pcy: "请检查您的收件箱以验证您的帐户",
      username: "用户名",
      psw: "密码",
      user: "用户",
      mi: "更多信息",
      vin: "船 IMO 号",
      icn: "公司代码",
      ea: "电邮地址",
      ci: "公司信息",
      imo: "IMO 号",
      ca: "公司地址",
      ba: "分支地址",
      cor: "注册国家",
      cal: "公司管理员列表",
      name: "名称",
      nd: "无数据",
      psd: "密码",
      doc: "文档",
      dn: "文档编号",
      dna: "文档名",
      dt: "文档类型",
      cb: "创建者",
      cdat: "创建日期时间",
      po: "职位",
      avp: "账户有效期",
      status: "状态",
      message: "信息",
      al: "操作日志",
      ai: "账户 ID",
      an: "账户名",
      act: "操作",
      role: "角色",
      dat: "日期时间",
      to: "至",
      inbox: "收件箱",
      sent: "发件箱",
      eaoin: "电邮地址或IMO号",
      type: "类型",
      num: "编号",
      spe: "说明",
      fd: "编辑文档格式",
      ar: "添加收件人",
      cas: "创建和签署",
      in: "电邮地址或IMO号",
      sd: "签署文档",
      tac: "条款和条件",
      tips: "点击以下按钮，即表示您已同意并承认您已阅 读以上所有内容。",
      ans: "同意并签署",
      es: "电子签名（请在方框内签名）",
      ss: "船只印章",
      aimg: "添加图片",
      signs: "签署成功",
      after: "稍后将向您发送一封确认电子邮件以供参考。",
      on: `{0}在{1}创建`,
      sat: "文档签署审计记录",
      vd: "预览证书",
      docID: "文档ID",
      certificate: "证书",
      ctips: "仅当所有签署人都对文档做出回复时才会生成证书",
      signa: "签名",
      vn: "船名",
      so: "签署时间",
      aisl: "坐标",
      port: "港口",
      signsu: "签名验证成功",
      signre: "文档已拒绝",
      ved: "验证文档",
      drag: "将文件拖放到此处以验证其内容",
      only: "仅上传.pdf文件",
      rd: "拒绝文档",
      rtips: "此操作不能被撤消。确认拒绝文件？",
      san: "设置新密码",
      password: "新密码",
      cpassword: "确认密码",
      logNow: "正在登录中...",
      noS: "无签名",
      vnd: "验证新文档",
      vnum: "版本号",
      vurl: "地址",
      vuc: "更新内容",
      viu: "是否更新",
      vfu: "是否强制更新",
      yes: "是",
      no: "否",
      ver: "版本",
      nNew: "此文档不是最新版本 ",
      doE: "文档验证失败.",
      doEC: "文档验证失败. 请上传有效文档. ",
      sta2: "文档已被签署，可是签署信息不存在此文档内",
      en: "英语",
      zh: "简体中文",
      ma: "我的应用",
      appn: "应用名称",
      ki: "密钥颁发时间",
      aa: "添加应用",
      scope: "范围",
      eapp: "编辑应用",
      ent: "基本信息",
      iden: "身份信息",
      add: "地址",
      rel: "关系",
      con: "联系",
      app: "应用",
      webSer: "回调地址",
      edUser: "编辑用户",
      deleteUser: "删除用户",
      RR: "重新发送请求",
      du: "停用用户",
      jA: "码头分配",
      booked: "已订约",
      pji: "待处理的码头信息",
      pbrt: "等待插入准备时间",
      ViewCertificate: "查看证书",
    },
    info: {
      ccode: "公司代码",
      cname: "公司名称",
      lname: "法定公司名称",
      et: "公司类型",
      coi: "公司注册地",
      is: "行业分类",
      ffd: "成立日期",
      rn: "注册号码",
      lei: "全球法人机构识别编码",
      pc: "母公司",
      bo: "公司最终受益人",
      en: "公司",
      sn: "街道编号",
      st: "街道",
      town: "市镇/地区",
      pz: "邮编",
      pb: "邮政信箱",
      email: "邮箱",
      web: "网址",
      tel: "电话",
      lin: "领英",
      face: "脸书",
      an: "公司别称",
      scope: "范围",
      ea: "修改应用",
    },
    state: {
      Active: "活跃",
      Deactivated: "暂停",
      PendingVerification: "等待验证",
    },
    ...zhLocale,
  },
  en: {
    status: {
      all: "All",
      pending: "Pending",
      signed: "Signed",
      rejected: "Rejected",
      success: " Success",
      pv: "Pending Verification",
      act: "Active",
      ina: "Inactive",
      warning: "Warning",
      created: "Created",
    },
    common: {
      allTanks: "All Tanks",
      jettySummary: "Jetty Summary",
      mapView: "Map View",
      cardView: "Card View",
      ompt: "Oil Marine Pte. Ltd. ",
      ppl: "Petro Private Limited",
      cpl: "Champion Pte. Ltd..",
      globalOil: "Global Oil",
      genesisEnergy: "Genesis Energy",
      oracleCompany: "Oracle Company",
      ubn: "Unassigned Barging Nominations",
      shipInformation: "Ship Information",
      ShipAISLocation: "Ship AIS Location",
      CHAMPION: "CHAMPION",
      vesselIMONumber: "Vessel IMO Number",
      iMOCompanyNumber: "IMO Company Number",
      speed: "Speed",
      course: "Course",
      draught: "Draught",
      timeline: "Timeline",
      jettySchedule: "Jetty Schedule",
      lastReport: "Last Report",
      lengthOverall: "Length Overall",
      status: "Status",
      Certificates: "Certificates",
      signDocument: "Sign Document",
      title: "Title",
      eSignature: "E-Signature (Please draw in the box to sign)",
      Stamp: "Stamp",
      AddImage: "Add Image",
      FileName: "File Name",
      DateCreated: "Date Created",
      link: "Link",
      SurveyorCompany: "Surveyor Company",
      CustomerCompany: "Customer Company",
    },
    menu: {
      sysAdmin: "System Administration",
      us: "Unqualified Ships",
      jm: "Jetty Management",
      tm: "Tank Management",
      cm: "Company Management",
      sm: "Ship Management",
      pm: "Pilot Management",
      bn: "Barging Nomination",
      quantity: "Certificate of Quantity",
      quality: "Certificate of Quality",
      billOfLading: "Bill of Lading",
      requests: "Requests",
      dashboard: "Dashboard",
      auditlog: "Audit Log",
      excel: "Excel",
      equipmentReport: "Equipment Report",
      settings:"Settings"
    },
    us: {
      addShip: "Add Ship",
      addUser: "Add User",
    },
    jm: {
      modifyBerth: "Modify Berth",
      createBerth: "Create Berth",
      berthName: "Berth Name",
      minimumLOA: "Minimum LOA",
      maximumLOA: "Maximum LOA",
      Name: "Name",
      editUser: "Edit User",
      AddUser: "Add User",
      EmailAddress: "Email Address",
      Role: "Role",
      CreateCompany: "Create Company",
      CompanyName: "Company Name",
      UENNumber: "UEN Number",
      Assigned: "Assigned",
      AllSurveyorCompanies: "All Surveyor Companies",
    },
    sm: {
      AddBargeOp: "Add Barge Operator",
      ModifyBargeOp: "Modify Barge Operator",
      BargeOpName: "Barge Operator Name",
      EmailAddress: "Email Address",
      AddShip: "Add Ship",
      ModifyShip: "Modify Ship",
      SelectBarge: "Select Barge",
      DeleteBargeOpWarningMsg: "Confirm delete barge operator?",
      RemoveBargeWarningMsg: "Confirm remove barge?",
      RemoveBargeWarningTitle: "Remove Barge"
    },
    table: {
      hideFromTerminal: 'Hide Barging Nomination from Terminal',
      no: "No.",
      shipName: "Ship Name",
      Name: "Name",
      iMONumber: "IMO Number ",
      username: "User Name",
      LOA: "LOA",
      portOfRegistry: "Port of Registry",
      EmailAddress: "Email Address",
      Role: "Role",
      Status: "Status",
      customer: "Customer",
      barge: "Barge",
      product: "Product",
      quantity: "Quantity",
      brt: "Barge Readiness Time",
      pilot: "Pilot",
      bat: "Berth Assign Timing",
      jetty: "Jetty",
      ETA: "ETA",
      bargeName: "Barge Name",
      contact: "PSA Contact Number",
      bargeReadiness: "Barge Readiness",
      pilotExemption: "Pilot Exemption",
      jettyNo: "Jetty No",
      berthAssignTiming: "Berth Assign Timing",
      estimatedBerthTiming: "Estimated Berth Timing",
      nomQtyMax: "Nom. Qty Max",
      qty: "Qty",
      BAL: "BAL",
      tank: "Tank",
      tanks: "Tank(s)",
      SUR: "SUR",
      block: "Block",
      doc: "Doc",
      remarks: "Remarks",
      surveyor: "Surveyor",
      surveyors: "Surveyor(s)",
      typeofPilot: "Type of Pilot",
      jettyInformation: "Jetty Information",
      nextETA: "Next ETA",
      Lcq: "Link Certificate of Quantity",
      Lcoq: "Link Certificate of Quality",
      Lbl: "Link Bill of Lading",
      Product: "Product",
      BalanceTank: "Balance In Tank",
      BargingInformation: "Barging Nomination Information",
      contactnumber: "Barge Contact Number",
      psacontactnumber: "PSA Contact Number",
      Opeinfo: "Operation Information",
      ship: "Ship",
      equipment: "Equipment",
      reportIssue: "Report Issue",
      reportedOn: "Reported On",
      reportedBy: "Reported By",
      resolvedOn: "Resolved On",
      addPilot: "Add Pilot",
      addTugBoat: "Add Tug Boat",
      name: "Name",
      createdBy: "Created By",
      createdOn: "Created On",
    },
    tips: {
      xz: "Please Select",
      sr: "Please Input",
      sd: "startDate",
      ed: "endData",
      du: `will you delete the {0}?`,
      sbo: `sent by {0} on {1}`,
      ro: `Received on {0}`,
      so: `Sent on {0}`,
      sto: `sent to {0} on {1}`,
      noMsg: "No Message",
      st: "System Tips",
      rr: "Your login status has expired, you can stay on this page or log in again",
      serverE: "Server Error",
      onlyP: "upload only .jpg .png .pdf file",
      must: "Required field must be filled in",
      psd: "The passwords entered twice do not match",
      url: "The URL is invalid or has been confirmed.",
      same: "Cannot add the same user!",
      userO: "Your account has expired",
      tips:
        "Thank you for participating in the trial for Marine Vessel Pass Digital Identity for Vessels. As this is a trial, the documents generated or signatures during this period will not be considered as legally binding.\n" +
        "\n" +
        "For more information, please email support{'@'}bunkerchain.io or contact your counterparty for more information.\n",
      deleteConfirmation: "Delete Confirmation"
    },
    btn: {
      activate: "Activate",
      aa: "Activate Account",
      cdp: "Change Display Picture",
      login: "Log In",
      edit: "Edit",
      add: "add",
      create: "Create",
      save: "Save",
      delete: "Delete",
      signed: "Signed",
      search: "Search",
      close: "Close",
      createDoc: "Create Document",
      searchDoc: "Search Document",
      sign: "Sign",
      reject: "Reject",
      book: "Book",
      sta: "Short Term Account",
      au: "Add User",
      eu: "Edit User",
      eus: "Active User",
      du: "Deactivate User",
      rr: "Resend Request",
      dr: "Delete Request",
      dus: "Delete User",
      cancel: "Cancel",
      send: "Send",
      comfirm: "Confirm",
      query: "Query",
      reset: "Reset",
      createMsg: "Create Message",
      uf: "Upload File",
      next: "Next",
      select: "Select",
      submit: "Submit",
      re: "Re-register",
      download: "Download",
      share: "Share",
      sd: "Share Document",
      reply: "Reply",
      lw: "Log in with IHS Markit",
      sa: "Select All",
      modify: "modify",
      SearchTankNumber: "Search Tank Number",
      CreateTank: "Create Tank",
      SearchBargeOp: "Search Barge Operator",
      AddBargeOp: "Add Barge Operator",
      ModifyTank: "Modify Tank",
      UploadCQ: "Upload CQ",
      UploadCOQ: "Upload COQ",
      AssignList: "Assign List",
      CreateCompany: "Create Company",
      SearchCompany: "Search Company",
      cbn: "Create Barging Nomination",
      TankName: "Tank Name",
      TankNumber: "Tank Number",
      Today: "Today",
      SelectAll: "Select All",
      ThisWeek: "This Week",
      ThisMonth: "This Month",
      ThisYear: "This Year",
      Jput: "Jput",
      Customer: "Customer",
      Surveyor: "Surveyor",
      Surveyors: "Surveyor(s)",
      PendingApproval: "Pending Approval",
      Acknowledged: "Acknowledge",
      viewAll: "View All",
      rforModification: "Request for Modification",
      cancelBargingNomination: "Cancel Barging Nomination",
      remove: "Remove",
      Cbn: "Create Barging Nomination",
      created: "Created",
      BargeReady: "Barge Ready",
      JettyAssigned: "Jetty Assigned",
      Sip: "Signing in Progress",
      Complete: "Complete",
      Completed: "Completed",
      Cancelled: "Cancelled",
      signIn: "Log In",
      lastUpdate: "Last Updated",
      CQUploaded: "CQ Uploaded",
      Unlink: "Unlink",
      Linked: "Linked",
      signasubmit: "Sign and Submit",
      signOfsubmit: "Sign and Submit",
      confirm: "Confirm",
      ReserSignature: "Reset Signature",
      SendDocument: "Send Document",
      JPUT: "JPUT",
      Acknowledge: "Acknowledge",
    },
    ifm: {
      error: "code error",
      pevc: "please enter verification code",
      ave: "A verification email has been sent to the email address registered under the Company Number/Vessel IMo",
      pcy: "Please check your inbox to verify your account",
      username: "Username",
      psw: "Password",
      mi: "More Information",
      psd: "Password",
      vin: "Vessel IMO Number",
      icn: "Company Code",
      ea: "Email Address",
      ci: "Company Information",
      ca: "Company Address",
      ba: "Branch Address",
      cor: "Country of Registration",
      cal: "Company Administrator List",
      name: "Name",
      nd: "No Data",
      doc: "Document",
      docID: "Document ID",
      dn: "Document Number",
      dna: "Document Name",
      user: "User",
      dt: "Document Type",
      cb: "Created By",
      cdat: "Created Date and Time",
      po: "Position",
      avp: "Account Validity Period",
      status: "Status",
      message: "Message",
      al: "Audit Log",
      ai: "Account ID",
      an: "Account Name",
      act: "Action",
      role: "Role",
      dat: "Date and Time",
      to: "To",
      inbox: "Inbox",
      sent: "Sent",
      eaoin: "Email Address or IMO Number",
      type: "Type",
      num: "Number",
      spe: "Specify",
      fd: "Format Document",
      ar: "Add Recipient",
      cas: "Create and Sign",
      in: "Emain Address or IMO Number",
      sd: "Sign Document",
      tac: "Terms and Conditions",
      tips: "By clicking the button below, I agree and acknowledge that I have read all of the above.",
      ans: "Agree and Sign",
      es: "E-Signature (please draw in the box to sign)",
      ss: "Ship Stamp",
      aimg: "Add Image",
      signs: "Signed Successfully",
      after:
        "A confirmation email for reference will be sent to you shortly after.",
      on: `{0} on {1}`,
      sat: "Signing Audit Trail",
      vd: "View Certificate",
      certificate: "Certificate",
      ctips:
        "Certificate will only be generated when all signees have responded to the document",
      signa: "Signature",
      vn: "Vessel Name",
      so: "Signed On",
      aisl: "AIS Location",
      port: "Port",
      signsu: "Signature is verified successfully",
      signre: "Document Rejected",
      imo: "IMO Number",
      ved: "Verify Document",
      drag: "Drag and drop file here to verify its content ",
      only: "upload only .pdf file",
      rd: "Reject Document",
      rtips: "This cannot be undone. Confirm to reject the document?",
      san: "Set a New Password",
      password: "New Password",
      cpassword: "Comfirm Password",
      logNow: "Logging in",
      noS: "No E-Signature",
      vnd: "Verify New Document",
      vnum: "Version Number",
      vurl: "Url",
      vuc: "Update Content",
      viu: "isUpdate",
      vfu: "isForceUp",
      yes: "Yes",
      no: "No",
      ver: "Version",
      nNew: "This PDF is not the latest version.",
      doE: "Document is invalid",
      doEC: "This document is not valid. Please upload a valid document.",
      sta2: "Document has been signed but signature is not present in the document.",
      en: "English",
      zh: "Chinese",
      ent: "Entity",
      iden: "Identification",
      add: "Address",
      rel: "Relationships",
      con: "Contact",
      ma: "My Apps",
      appn: "App Name",
      ki: "Key Issued",
      aa: "Add App",
      scope: "Scope",
      eapp: "Edit App",
      app: "App",
      webSer: "RedirectUri",
      edUser: "Edit User",
      deleteUser: "Delete User",
      RR: "Resend Request",
      du: "Deactivate User",
      pji: "Pending Jetty Information",
      pbrt: "Pending Barge Readiness Time",
      jA: "Jetty Assigned",
      booked: "Booked",
      ViewCertificate: "View Certificate",
    },
    info: {
      ccode: "Company Code",
      cname: "Company Name",
      lname: "Legal Trading Name",
      et: "Entity Type",
      coi: "Country of Incorporation",
      is: "Industry Sector",
      ffd: "Full Founded Date",
      rn: "Register Number",
      lei: "Legal Entity Identifier",
      pc: "Parent Company",
      bo: "Beneficial Owner",
      en: "Entity",
      sn: "Street No",
      st: "Street",
      town: "Town",
      pz: "Postal Code Zip Code",
      pb: "Po Box",
      email: "Email",
      web: "Website",
      tel: "Telephone",
      lin: "Linkedin",
      face: "Facebook",
      an: "Alternative Name",
    },
    state: {
      Active: "Active",
      Deactivated: "Deactivated",
      PendingVerification: "Pending Verification",
    },
    ...enLocale,
  },
};
