import Vuex from "vuex";
import Vue from "vue";
import router from "@/router";
import Layout from "/src/layout/index.vue";
import { getNotificationList, getUserInfo, login, LogOut, mvpLogin } from "@/services/user";
import { companyRoute, getRoutes, requests } from "@/router/all-route";
import { removeToken } from "@/utils/auth";
const Ships = () => import("/src/view/admin/unqualified-ships.vue");
const Jetty = () => import("/src/view/admin/jetty.vue");
const Tank = () => import("/src/view/admin/tank.vue");
const Company = () => import("/src/view/admin/company.vue");
const Barging = () => import("/src/view/barging");
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: null,
    routes: [],
    locale: "en",
    token: "",
    notifications: [],
    total: 0,
    screenType:{
      isMobileScreen:false,
      isMobileOrTabletScreen:false,
      isLaptop:false,
      mainLayoutWidth:0
    },
    
    mvpVesselPayFlag: false,
  },
  mutations: {
    SET_USERINFO(state, userInfo) {
      state.userInfo = { ...userInfo };
    },
    SET_CARDACTIVE(state, mvpVesselPayFlag) {
      state.mvpVesselPayFlag = mvpVesselPayFlag;
    },
    SET_ROUTES(state, routes) {
      state.routes = routes;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    changeLocale(state, value) {
      state.locale = value;
    },
    SET_NOTIFICATIONS(state, {notifications, total}) {
      state.notifications = [ ...state.notifications, ...Array.from(notifications || []) ];
      state.total = total;
    },
    CLEAR_NOTIFICATIONS(state) {
      state.notifications = [];
    },
    SET_SCREEN(state, value) {
      state.screenType = value;
    },
  },
  actions: {
    goLogin(commit, payLoad) {
      return new Promise((resolve, reject) => {
        let request = payLoad.isMvp ? mvpLogin(payLoad) : login(payLoad);
        request
          .then((res) => {
            if (res.code === 200) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    clearNotificationList({ commit }) {
      commit("CLEAR_NOTIFICATIONS")
    },
    getNotificationList({ commit }, page = 1) {
      return new Promise((resolve, reject) => {
        getNotificationList(page)
          .then((res) => {
            if (!res) return;
            commit("SET_NOTIFICATIONS", {notifications: res.data.rows, total: res.data.total})
            resolve();
          })
          .catch((err) => reject(err));
      })
    },
    getUserInfo({ commit, dispatch }, isMvp) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            if (!res) return;
            let routeList = getRoutes(res.user.userType);
            let role = res.user.role;
            let userType = res.user.userType;
            let terminalLogisticRole = userType == 1 && role == 1
            let terminalPilotRole = userType == 1 && role == 10
            let terminalHeadPilotRole = userType == 1 && role == 12

            // for non logistic role, must take out export data menu
            if( terminalLogisticRole ) {
              routeList.map((item, index) => {
                if(item.path === "/excel") {
                  item.children = item.children.filter(e => e.path == "logistic/index")
                }
              })
            } else if( terminalPilotRole || terminalHeadPilotRole ) {
              routeList.map((item, index) => {
                if(item.path === "/excel") {
                  item.children = item.children.filter(e => e.path == "pilot/index")
                } 
              })
            } else {
              routeList = routeList.filter((item) => item.path !== "/excel")
            }

            // for non head of pilot, must filter out the pilot management
            if( ! terminalHeadPilotRole ) {
              routeList.map((item, index) => {
                if(item.path === "/admin") {
                  item.children = item.children.filter(e => e.path != "pilot")
                } 
              })
            }


            if (role == 1 || role == 2) {
              let target = routeList.find((item) => item.path === "/requests");
              if (!target) {
                routeList.push(requests);
              }
            }
            if (role == 8 && userType == 4) {
              routeList = routeList.filter((item) => item.path !== "/admin")
            }
            // if (isMvp) {
            //   routeList = routeList.filter((item) => item.path !== "/admin")
            // }
            // // 客户公司管理员
            // if (userType == 2 && role == 4) {
            //   let target = routeList.find((item) => item.path === "/admin");
            //   if (!target) {
            //     routeList.splice(0, 0, companyRoute);
            //   }
            // }
            // 测量公司管理员
            // if (userType == 3 && role == 6) {
            //   let target = routeList.find((item) => item.path === "/admin");
            //   if (!target) {
            //     routeList.splice(0, 0, companyRoute);
            //   }
            // }
            router.addRoutes(routeList);
            commit("SET_USERINFO", res.user);
            commit("SET_CARDACTIVE", res.mvpVesselPayFlag)
            commit("SET_ROUTES", routeList);
            resolve(routeList[0].redirect);
          })
          .catch((err) => reject(err));
      });
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        LogOut(state.token)
          .then(() => {
            commit("SET_USERINFO", {});
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setScreen({ commit},data) {
      commit("SET_SCREEN",data);
    },
  },
});
export default store;
